<template>
  <div class="tal-text-red-600 tal-text-sm-px tal-bg-gray-100 tal-pl-5 tal-pt-4">
    {{_.get(getError,'shou_ji_bu_me.bu_men_ren_ding_de_cheng_hao')}}
  </div>
  <van-checkbox-group @change="ji_shu_1_fang_dou" v-model="jiShuXuanZe.ji_shu_1">

    <div class="tal-border-solid tal-border-gray-100 tal-border-0 tal-border-t-6">
      <div class="tal-flex tal-items-center tal-p-4">
        <div class="tal-w-60 tal-text-van-label-color">
          1、高新技术企业
        </div>
        <div class="tal-flex-1">
          <van-checkbox shape="square" name="1"/>
        </div>
      </div>
    </div>
    <div class="tal-border-solid tal-border-gray-100 tal-border-0 tal-border-t-6">
      <div class="tal-flex tal-items-center tal-p-4">
        <div class="tal-w-60 tal-text-van-label-color">
          2、技术创新示范企业
        </div>

        <div class="tal-flex-1 tal-flex tal-space-x-5">
          <van-checkbox name="2" shape="square" @click="delJiShu('3')">国家级</van-checkbox>
          <van-checkbox name="3" shape="square" @click="delJiShu('2')">省级</van-checkbox>
        </div>
      </div>
    </div>
    <div class="tal-border-solid tal-border-gray-100 tal-border-0 tal-border-t-6">
      <div class="tal-flex tal-items-center tal-p-4">
        <div class="tal-w-60 tal-text-van-label-color">
          3、质量标杆
        </div>
        <div class="tal-flex-1">
          <van-checkbox shape="square" name="4"/>
        </div>
      </div>
    </div>
    <div class="tal-border-solid tal-border-gray-100 tal-border-0 tal-border-t-6">
      <div class="tal-flex tal-items-center tal-p-4">
        <div class="tal-w-60 tal-text-van-label-color">
          4、中国名牌产品
        </div>
        <div class="tal-flex-1">
          <van-checkbox shape="square" name="5"/>
        </div>
      </div>
    </div>
    <div class="tal-border-solid tal-border-gray-100 tal-border-0 tal-border-t-6">
      <div class="tal-flex tal-items-center tal-p-4">
        <div class="tal-w-60 tal-text-van-label-color">
          5、其他（请说明）
        </div>
        <div class="tal-flex-1">
          <van-checkbox shape="square" name="6"/>
        </div>
      </div>
      <div class="tal-p-4 tal-pt-0" v-if="isShowShuoMing">
        <van-field
            placeholder="请输入"
            class="tal-border-solid tal-border-0 tal-border-b"
            v-model="form.shou_ji_bu_me.bu_men_ren_ding_de_cheng_hao_shuo_ming"
        />
      </div>
      <div class="tal-pl-4 tal-pb-4 tal-text-red-600 tal-text-sm-px">
        {{_.get(getError,'shou_ji_bu_me.bu_men_ren_ding_de_cheng_hao_shuo_ming')}}
      </div>
    </div>
  </van-checkbox-group>
  <div class="tal-border-solid tal-border-gray-100 tal-border-0 tal-border-t-6">
    <shou-ji-duo-xuan
        label-width="145"
        v-model:ids="form.shou_ji_bu_me.shi_fou_you_zi_zhu_pin_pai"
        v-model:shuo-ming="form.shou_ji_bu_me.zi_you_pin_pai_name"
        input-type="radio"
        :label="selectList['7'].name"
        :data-list="selectList['7'].children"
        :error-message="_.get(getError,'shou_ji_bu_me.shi_fou_you_zi_zhu_pin_pai',_.get(getError,'shou_ji_bu_me.zi_you_pin_pai_name'))"
    />
  </div>
  <div class="tal-border-solid tal-border-gray-100 tal-border-0 tal-border-t-6 tal-border-b-6">
    <shou-ji-duo-xuan
        v-model:ids="form.shou_ji_bu_me.lv_se_zhi_zao_ti_xi_ren_zheng_qing_kuang"
        v-model:shuo-ming="form.shou_ji_bu_me.lv_se_zhi_zao_ti_xi_ren_zheng_qing_kuang_shuo_ming"
        label-width="145"
        :label="selectList['8'].name"
        :data-list="selectList['8'].children"
        :error-message="_.get(getError,'shou_ji_bu_me.lv_se_zhi_zao_ti_xi_ren_zheng_qing_kuang',_.get(getError,'shou_ji_bu_me.lv_se_zhi_zao_ti_xi_ren_zheng_qing_kuang_shuo_ming'))"
    />
  </div>
  <div class="tal-border-solid tal-border-gray-100 tal-border-0 tal-border-t-6 tal-border-b-6 ">
    <div class="tal-pl-10 tal-pt-4 tal-text-van-label-color">
      企业诉求
    </div>
    <div class="tal-pl-4">
      <van-field
          rows="2"
          v-model="form.shou_ji_bu_me.qi_ye_su_qiu"
          autosize
          type="textarea"
          placeholder="请输入"
          :error-message="_.get(getError,'shou_ji_bu_me.shi_fou_huo_de_guo_jia_ji_ke_ji_jiang_li')"
      />
    </div>
  </div>
  <div class="tal-border-solid tal-border-gray-100 tal-border-0 tal-border-t-6 tal-border-b-6 ">
    <div class="tal-pl-10 tal-pt-4 tal-text-van-label-color">
      对政策的疑问
    </div>
    <div class="tal-pl-4">
      <van-field
          rows="2"
          v-model="form.shou_ji_bu_me.dui_zheng_ce_de_yi_wen"
          autosize
          type="textarea"
          placeholder="请输入"
          :error-message="_.get(getError,'shou_ji_bu_me.shi_fou_huo_de_guo_jia_ji_ke_ji_jiang_li')"
      />
    </div>
  </div>
  <div class="tal-border-solid tal-border-gray-100 tal-border-0 tal-border-t-6 tal-border-b-6 ">
    <div class="tal-pl-10 tal-pt-4 tal-text-van-label-color">
      当前市场行情描述， 对经济的信心、疫情影响，希望政府给到的政策和帮助、建议等 （250字以内）
    </div>
    <div class="tal-pl-4">
      <van-field
          rows="2"
          v-model="form.shou_ji_bu_me.dang_qian_shi_chang_hang_qing_miao_shu"
          autosize
          type="textarea"
          placeholder="请输入"
          :error-message="_.get(getError,'shou_ji_bu_me.dang_qian_shi_chang_hang_qing_miao_shu')"
      />
    </div>
  </div>
  <div class="tal-border-solid tal-border-gray-100 tal-border-0 tal-border-t-6 tal-border-b-6 ">
    <div class="tal-pl-10 tal-pt-4 tal-text-van-label-color">
      企业产品销售情况， 近几年销售（订单）、生产、营收情况 （250字以内）
    </div>
    <div class="tal-pl-4">
      <van-field
          rows="2"
          v-model="form.shou_ji_bu_me.qi_ye_chan_pin_xiao_shou_qing_kuang"
          autosize
          type="textarea"
          placeholder="请输入"
          :error-message="_.get(getError,'shou_ji_bu_me.qi_ye_chan_pin_xiao_shou_qing_kuang')"
      />
    </div>
  </div>
  <div class="tal-border-solid tal-border-gray-100 tal-border-0 tal-border-t-6 tal-border-b-6 ">
    <div class="tal-pl-10 tal-pt-4 tal-text-van-label-color">
      所属行业前景描述， 行业发展、前景、转型情况等 （250字以内）
    </div>
    <div class="tal-pl-4">
      <van-field
          rows="2"
          v-model="form.shou_ji_bu_me.suo_shu_hang_ye_qian_jing_miao_shu"
          autosize
          type="textarea"
          placeholder="请输入"
          :error-message="_.get(getError,'shou_ji_bu_me.suo_shu_hang_ye_qian_jing_miao_shu')"
      />
    </div>
  </div>

</template>

<script>
//获得相关部门认定的称号
import {inject, onMounted, reactive, watch, computed} from "vue";
import XEUtils from "xe-utils";
import {get} from "@/api/http";
import ShouJiDuoXuan from "@/views/ShouJiInfo/components/ShouJiDuoXuan";
import _ from 'lodash'
export default {
  name: "HuoDeXiangGuanBuMenRenDingDeChengHao",
  components: {ShouJiDuoXuan},
  setup() {
    const getError = inject('get-error')
    const form = inject('lol-form')
    const jiShuXuanZe = reactive({
      ji_shu_1: [],
    })
    //是否显示其他说明输入框
    const isShowShuoMing = computed(() => {
      return XEUtils.includes(jiShuXuanZe.ji_shu_1, '6')
    })

    //监听选项改变
    const ji_shu_1_fang_dou = XEUtils.debounce(() => {
      form.value.shou_ji_bu_me.bu_men_ren_ding_de_cheng_hao = jiShuXuanZe.ji_shu_1.join(',')
    }, 300)

    function delJiShu(id) {
      XEUtils.remove(jiShuXuanZe.ji_shu_1, n => n === id)
      ji_shu_1_fang_dou()
    }

    //坚挺上级改变
    watch(() => form.value.shou_ji_bu_me.bu_men_ren_ding_de_cheng_hao, () => {
      jiShuXuanZe.ji_shu_1 = XEUtils.toValueString(form.value.shou_ji_bu_me.bu_men_ren_ding_de_cheng_hao).split(',')
      jiShuXuanZe.ji_shu_1 = XEUtils.filter(jiShuXuanZe.ji_shu_1, n => !XEUtils.isEmpty(n))
    }, {
      immediate: true
    })


    const selectList = reactive({
      '7': {name: '', children: []},
      '8': {name: '', children: []},
    })

    onMounted(() => {
      get('/portal/index/get_shou_ji_config').then(da => {
        selectList['7'] = da.data['7']
        selectList['7'].children = XEUtils.toArray(selectList['7'].children)
        selectList['8'] = da.data['8']
        selectList['8'].children = XEUtils.toArray(selectList['8'].children)
      })
    })


    watch(() => jiShuXuanZe.ji_shu_1, () => {
      if (isShowShuoMing.value) {
        form.bu_men_ren_ding_de_cheng_hao_shuo_ming = ''
      } else {
        form.bu_men_ren_ding_de_cheng_hao_shuo_ming = undefined
      }
    })
    return {
      _,
      getError,
      isShowShuoMing,
      delJiShu,
      ji_shu_1_fang_dou,
      selectList,
      form,
      jiShuXuanZe
    }
  }
}
</script>

<style scoped>

</style>
