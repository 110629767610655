
import {reactive, computed} from 'vue'
import SelectXiaDan from "@/components/select/SelectXiaDan.vue";
import {ref, watch} from "vue";
import XEUtils from "xe-utils";

//二级联动
/**
 *     <lian-dong v-model:id1="form.show_ji.suo_shu_hang_ye" v-model:id2="form.show_ji.ju_ti_xi_fen_ling_yu">
 *       <template #input1="{getName}">
 *         <div class="tal-bg-white tal-mt-2 tal-mb-2">
 *           <van-field
 *               label-width="100"
 *               label="所属行业"
 *               placeholder="请选择所属行业"
 *               readonly
 *               is-link
 *           >
 *             <template #input v-if="getName">{{getName}}</template>
 *           </van-field>
 *         </div>
 *       </template>
 *       <template #input2="{getName}">
 *         <div class="tal-bg-white tal-mt-2 tal-mb-2">
 *           <van-field
 *               label-width="100"
 *               v-model="form.show_ji.ju_ti_xi_fen_ling_yu"
 *               label="具体细分领域"
 *               placeholder="请选择具体细分领域"
 *               readonly
 *               is-link
 *           >
 *             <template #input v-if="getName">{{getName}}</template>
 *           </van-field>
 *         </div>
 *       </template>
 *     </lian-dong>
 */
export default {
  name: "LianDong",
  components: {SelectXiaDan},
  props: {
    url: String,
    id1: [String, null],
    id2: [String, null],
  },
  setup(props, ext) {
    const select = reactive({
      value: '',
      name: '',
    })
    const xiaLa = ref()

    function show() {
      xiaLa.value.show()
    }

    //监听id改变
    const jian_ting_id = XEUtils.debounce(() => {
      ext.emit('update:id1', XEUtils.get(select.value.split(','), '0', ''))
      ext.emit('update:id2', XEUtils.get(select.value.split(','), '1', ''))
    }, 300)
    watch(() => select.value, () => {
      jian_ting_id()
    })

    //监听上级id修改
    const jian_ting_shang_ji_id = XEUtils.debounce(() => {
      select.value = XEUtils.filter([props.id1, props.id2], n => n).join(',')
    }, 300)
    watch(() => [props.id1, props.id2], () => {
      // console.log(props.id1, props.id2)
      jian_ting_shang_ji_id()
    }, {immediate: true})


    const getName = computed(() => {
      let name = {
        '1': XEUtils.get(select.name.split(','), '0', ''),
        '2': XEUtils.get(select.name.split(','), '1', ''),
      }
      return name
    })


    return {
      getName,
      xiaLa,
      show,
      select
    }
  }
}
