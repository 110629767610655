<template>

  <div class="page tal-w-full flex-col tal-bg-white" >
    <div class="shou-ji-info-bg van-ellipsis tal-w-full tal-text-center">
      {{ get(listData, 'shouJiData.qi_ye_ming_cheng') }}
    </div>
    <div class="jin-ru-zhuang-tai tal-text-center">
      <span>
        已成功进入企业培育库
        <div class="xiao-tiao"></div>
      </span>
    </div>

    <div
        class="cha-kan-nei-rong tal-pt-8 tal-text-center tal-cursor-pointer"
        v-if="cssType ==='mobile'"
    >
      <span class="tal-p-2 tal-pl-6 tal-pr-6" @click="showTianXie">
        查看已填写内容
      </span>
    </div>
    <div class="fen-lei-tong-ji">
      <div>
        <div class="zong-fen-shu tal-mb-2">总分：<span>{{ get(listData.shouJiData, 'fen_shu', '0') }}</span>分</div>
        <div class="dan-qian tal-mb-2">企业当前梯度：{{ get(listData.shouJiData, 'join_ti_du_gui_ze_id.name', '入库中') }}</div>
      </div>
      <div>
        <img
            class=" has-focus"
            src="http://yunzuofile.sxsgky.com/cea00a9c2378b632ba6ba4df300c50061.png"
        />
      </div>
    </div>
    <div class="ti-shi tal-mt-2 tal-mb-4">以下自测结果仅供参考，最终结果以实际申报情况为准</div>
    <div class="shui-yin"></div>
    <div class="tal-pl-5 tal-pr-5">

      <div class="box_1" v-for="(vo, index) in listData.tuiDuInfo" :key="index">
        <div class="group_1">
          <span class="text_1">{{ index + 1 }}</span>
          <div class="text-wrapper_1">
            <span class="text_2">企业实际数据：</span>
            <span class="text_3">{{ vo.title }}</span>
            <span class="text_4">
              <div class="fen-shu" >
                <span class="number" :style="{ color: vo.color }">{{get(vo, 'wei_da_biao_zhi_biao.fen_shu', 0)}}</span>
                分
              </div>
            </span>
          </div>
        </div>
        <div class="text-wrapper_2">
          <div class="text_4"> 标准：</div>
          <div class="text_5">
            <div class="text-wrapper_3 tal-mb-2" v-for="(vo2, index2) in vo.biao_zhun" :key="index2">
              {{ vo2.value }}
            </div>
          </div>
        </div>
        <div class="text-wrapper_7 flex-row justify-between" v-if="vo.cuo_shu_miao_shu">
          <span class="text_11">培育措施：</span>
          <span class="text_12">
            {{ vo.cuo_shu_miao_shu }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { nextTick, onMounted, reactive } from 'vue'
import http from "@/api/http.js";
import to from 'await-to-js'
import { map, get, isObject, each, toString, clear, parseUrl } from 'xe-utils'
import { isEmpty, toNumber } from 'lodash'

export default {
  name: 'FeiShuInfo',
  props: {
    shouJiId: {
      type: [String, Number],
      default() {
        return get(parseUrl(location.href), 'searchQuery.shouJiId', 0)
      }
    },
    cssType: {
      type: String, //格式有 mobile
      default() {
        return '';
      }
    }
  },

  setup(props,{emit}) {
    onMounted(() => {
      show(props.shouJiId)
    })

    //获取梯度配置
    function getTiDuConfig() {
      return http.get('/portal/index/getTiDuConfig')
    }

    //获取企业详情
    function shouJiInfo() {
      return http.get('/portal/index/shouJiInfo', {params: {shouJiId: props.shouJiId}})
    }

    const pageData = reactive({
      pageStatus: false
    })

    const listData = reactive({
      shouJiData: {},
      tuiDuInfo: []
    })

    async function show(shouJiId) {
      clear(listData.shouJiData)
      clear(listData.tuiDuInfo)

      const shouJiData = await to(shouJiInfo({shouJiId}))
      if (shouJiData[0]) return
      const tuiDuInfo = await to(getTiDuConfig())
      if (tuiDuInfo[0]) return
      listData.shouJiData = shouJiData[1].data
      listData.tuiDuInfo = tuiDuInfo[1].data

      //内容转换
      listData.tuiDuInfo = map(listData.tuiDuInfo, (n) => {
        const item = get(listData.shouJiData, ['wei_da_biao_zhi_biao', get(n, 'id')])
        n.wei_da_biao_zhi_biao = isObject(item) ? item : false

        //名称关键字替换
        each(get(n, 'wei_da_biao_zhi_biao.jie_guo', []), (n2) => {
          n.title = toString(n.title).replace(`[第${n2.group + 1}组]`, n2.msg)
        })

        //获取匹配措施
        const fen_shu = toNumber(get(item, 'fen_shu', 0)) //获取当前组的分数
        n.cuo_shu_miao_shu = ''
        each(n.pei_yu_cuo_shi, (n2) => {
          if (!isEmpty(n.cuo_shu_miao_shu)) return
          if (fen_shu >= toNumber(n2.fen_shu)) {
            n.color = n2.color
            n.cuo_shu_miao_shu = n2.miao_shu
          }
        })

        return n
      })
      nextTick(() => {
        pageData.pageStatus = true
      })
    }

    function showTianXie(){
      console.log('----')
      emit('showTianXie')
    }

    return {
      showTianXie,
      show,
      get,
      pageData,
      listData
    }
  }
}
</script>

<style scoped lang="less">
@basis-size: 14px;
@shouJiId: #104868;
.jin-ru-zhuang-tai {
  color: @shouJiId;
  span {
    font-size: 20px;
    position: relative;
    padding-right: 5px;
    padding-left: 5px;

    .xiao-tiao {
      height: 6px;
      width: 100%;
      position: absolute;
      bottom: -5px;
      left: 0;
      border-radius: 50px;
      background-color: #D1DCE2;
    }
  }
}

.cha-kan-nei-rong {
  span {
    font-size: 16px;
    border-radius: 100px;
    background-color: #3277BB;
    color: #fff;
  }
}

.fen-lei-tong-ji {
  margin-top: -9px;
  padding-left: 40px;
  padding-right: 1.25rem;
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  justify-content: space-between;
  align-items: flex-end;
  font-size: 15px;
  color: @shouJiId;

  .zong-fen-shu {
    span {
      font-size: 28px;
      font-weight: bold;
    }
  }

  .has-focus {
    width: 80px;
  }
}

.ti-shi {
  padding-left: 40px;
  padding-right: 10px;
  color: #908F8F;
}

.ti-shi-div {
  padding-left: 28px;
  padding-right: 28px;
  font-size: 18px;

  .ti-shi {
    color: @shouJiId;
    opacity: .6;
  }
}

.shou-ji-info-bg {
  font-size: 28px;
  padding-right: 32px;
  padding-left: 32px;
  padding-top: 20px;
  padding-bottom: 20px;
  background-image: -webkit-linear-gradient(bottom, red, #0062B5, #3ec7e7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.page {
  position: relative;
  //background-image: url("http://yunzuofile.sxsgky.com/default/20230303/88c4fdfb8cdd1c04d0ad37f47259a7a1.png");
  background-size: 100% auto;
  background-position-y: bottom;
  background-repeat: no-repeat;
  padding-bottom: 10px;


  .tong-ji {
    padding-top: 12px;
    padding-bottom: 6px;
    display: flex;
    flex-direction: row;
    align-content: flex-end;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .name {
      padding-left: 28px;
      font-size: @basis-size;
      color: @shouJiId;
    }

    .zong-fen-shu {
      font-size: 28px;
      font-weight: bold;
    }

    .xiao-ren-tou {
      width: 100px;
      height: 100px;
    }
  }

  .box_1 {
    margin-bottom: 20px;

    .group_1 {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: flex-start;
      justify-content: space-evenly;
      align-items: flex-start;
      color: @shouJiId;

      .text_1 {
        white-space: break-spaces;
        width: 30px;
        color: rgba(16, 72, 104, 1);
        font-size: 16px;
        font-weight: 500;
        padding-top: 8px;
      }

      .text-wrapper_1 {
        background-color: rgba(217, 226, 233, 1);
        font-size: 15px;
        padding: 10px 10px;
        flex: 1;
        display: flex;
        align-content: center;
        align-items: stretch;

        .text_2 {
          //padding-right: 10px;
        }

        .text_3 {
          flex: 1;
        }

        .text_4 {
          text-align: right;
          padding-left: 5px;
          display: flex;
          flex-direction: row;
          align-content: space-around;
          align-items: center;
          justify-content: flex-end;
          position: relative;
          width: 100px;

          .fen-shu {
            position: absolute;
            top: 50%;
            width: 100%;
            right: 0;
            transform: translateY(-18px);
            .number{
                font-size: 24px;
            }
          }
        }
      }
    }

    .text-wrapper_2 {
      display: flex;
      padding-left: 30px;
      padding-top: 16px;
      font-size: 14px;

      .text_4 {
        font-size: @basis-size;
        color: #104868;
      }

      .text_5 {
        flex: 1;
        color: rgba(16, 72, 104, 1);
        font-size: @basis-size;
        text-align: left;
        margin-bottom: 12px;
      }

      .text_6 {
        width: 287px;
        height: 33px;
        overflow-wrap: break-word;
        color: rgba(16, 72, 104, 1);
        font-size: 24px;
        font-weight: 500;
        text-align: left;
        white-space: nowrap;
        line-height: 33px;
      }
    }

    .text-wrapper_7 {
      display: flex;

      .text_11 {
        padding-left: 35px;
        //width: 120px;
        color: rgba(16, 72, 104, 1);
        font-size: @basis-size;
      }

      .text_12 {
        white-space: break-spaces;
        flex: 1;
        color: rgba(16, 72, 104, 1);
        font-size: @basis-size;
        text-align: left;
      }
    }
  }
}
</style>
