
import {ref, onMounted} from "vue";
import http from "@/api/http.js";
import _ from 'lodash'
import SeeInfo from "@/views/ShouJiInfo/components/SeeInfo.vue";
import FeiShuInfo from "@/views/ShouJiInfo/feiShuInfo.vue";

export default {
  name: "WanBi",
  components: {FeiShuInfo, SeeInfo},
  setup() {
    const isShowInfo = ref(false) //true显示分数详情
    const status = ref('')
    const showJiInfo = ref({
      id: '',
      fen_shu: ''
    })
    onMounted(function () {
      http.get("/gzhphp/shou_ji_info/get_shou_ji_xin_xi_status").then(da => {
        status.value = _.get(da, 'data.join_ti_du_gui_ze_id.name', '入库中')
        showJiInfo.value = _.get(da, 'data', {})
        isShowInfo.value = true
      })
    })

    function showInfo() {
      isShowInfo.value = true
    }

    const seeInfoRef = ref()
    function showTianXie(){
      seeInfoRef.value.show()
    }

    return {
      seeInfoRef,
      showTianXie,
      isShowInfo,
      status,
      showInfo,
      showJiInfo
    }
  }
}
