<template>
  <van-action-sheet v-model:show="status" style="height: 70%;" title="详细内容">
    <div class="tal-w-full">
      <table class="table table-bordered ">
        <tbody>
        <tr>
          <td class="tal-break-all" style="width: 130px;">企业名称</td>
          <td class="tal-break-all" colspan="3">{{ info.qi_ye_ming_cheng }}</td>

        </tr>
        <tr>
          <td class="tal-break-all" style="width: 130px;">联系人</td>
          <td class="tal-break-all" colspan="3">{{ info.lian_xi_ren }}</td>
        </tr>
        <tr>
          <td class="tal-break-all">手机号</td>
          <td  class="tal-break-all" colspan="3">{{ info.mobile }}</td>
        </tr>
        <tr>
          <td class="tal-break-all" >
            统一社会信用代码
          </td>
          <td class="tal-break-all" colspan="3"> {{ info.xin_yong_dai_ma }}</td>
        </tr>
        <tr>
          <td class="tal-break-all">联系微信号</td>
          <td class="tal-break-all" colspan="3">
            {{ info.wx_number }}
          </td>

        </tr>
        <tr>
          <td class="tal-break-all">所属行业</td>
          <td class="tal-break-all">{{ info.suo_shu_hang_ye }}</td>
          <td class="tal-break-all">具体细分领域</td>
          <td class="tal-break-all">{{ info.ju_ti_xi_fen_ling_yu }}</td>
        </tr>
        <tr>
          <td class="tal-break-all">企业所属街道</td>
          <td class="tal-break-all" colspan="3">
            {{ info.qi_ye_suo_shu_jie_dao }}
          </td>
        </tr>
        <tr>
          <td class="tal-break-all">企业类型</td>
          <td class="tal-break-all" colspan="3">
            {{ info.qi_ye_lei_xing }}
          </td>
        </tr>
        <tr>
          <td colspan="4" class="tal-break-all">
            <h5>公司简介</h5>
            {{ info.gong_si_jian_jie }}
          </td>
        </tr>
        <tr>
          <td class="tal-break-all">全职员工数量</td>
          <td class="tal-break-all">{{ info.yuan_gong_shu_liang }}人</td>
          <td class="tal-break-all">其中研发人员数量</td>
          <td class="tal-break-all">{{ info.yan_fa_ren_shu_liang }}人</td>
        </tr>
        <tr>
          <td class="tal-break-all">营业收入</td>
          <td class="tal-break-all">{{ info.ying_ye_shou_ru }}万元</td>
          <td class="tal-break-all">主营业务收入</td>
          <td class="tal-break-all">{{ info.zhu_ying_ye_wu_shou_ru }}万元</td>
        </tr>
        <tr>
          <td class="tal-break-all">主营业务收入增长率</td>
          <td class="tal-break-all">{{ info.zhu_ying_shou_ru_zeng_zhang_lv }}%</td>
          <td class="tal-break-all">净利润总额</td>
          <td class="tal-break-all">{{ info.jing_li_run_zong_e }}万元</td>
        </tr>
        <tr>
          <td class="tal-break-all">净利润总额增长率</td>
          <td class="tal-break-all">{{ info.li_run_zeng_zhang_lv }}%</td>
          <td class="tal-break-all">资产总额</td>
          <td class="tal-break-all">{{ info.zi_chan_zong_e }}万元</td>
        </tr>
        <tr>
          <td class="tal-break-all">资产负债率</td>
          <td class="tal-break-all">{{ info.zi_chan_fu_zhai_lv }}%</td>
          <td class="tal-break-all">当前企业属于</td>
          <td class="tal-break-all">{{ info.dang_qian_qi_ye_shu_yu }}</td>
        </tr>
        <tr>
          <td class="tal-break-all">主导产品名称（中文）</td>
          <td class="tal-break-all">{{ _.get(info, 'join_shou_ji_zhuan_ye.zhu_dao_chan_pin_ming_cheng') }}</td>
          <td class="tal-break-all">主导产品类别</td>
          <td class="tal-break-all">{{ _.get(info, 'join_shou_ji_zhuan_ye.zhu_dao_chan_pin_lei_bie') }}</td>
        </tr>
        <tr>
          <td class="tal-break-all">
            企业从事特定<br/>细分市场时间
          </td>
          <td class="tal-break-all">{{ _.get(info, 'join_shou_ji_zhuan_ye.xi_fen_shi_chang_shi_jian') }}年</td>
          <td class="tal-break-all">
            主营业务收入占<br/>营业收入比重
          </td>
          <td class="tal-break-all">{{ _.get(info, 'join_shou_ji_zhuan_ye.ying_ye_shou_ru_bi_zhong') }}%</td>
        </tr>
        <tr>
          <td class="tal-break-all" >
            近 2 年主营业务<br/>收入平均增长率
          </td>
          <td class="tal-break-all" colspan="3">{{ _.get(info, 'join_shou_ji_zhuan_ye.shou_ru_ping_jun_zeng_zhang_lv') }}%</td>
        </tr>
        <tr>
          <td class="tal-break-all" colspan="4">
            <h5>近期是否有无工厂（生产线）项目新建、扩建、技改等计划（250字以内）</h5>
            {{ _.get(info, 'join_shou_ji_zhuan_ye.jin_qi_shi_fou_you_wu_gong') }}
          </td>

        </tr>
        <tr>
          <td class="tal-break-all">企业获得的管理体系认证情况</td>
          <td class="tal-break-all" v-html="_.get(info,'join_shou_ji_zhuan_ye.ren_zheng_qing_kuang')"></td>
          <td class="tal-break-all">核心业务采用信息系统支撑情况</td>
          <td class="tal-break-all" v-html="_.get(info, 'join_shou_ji_zhuan_ye.zhi_cheng_qing_kuang', '')"></td>
        </tr>
        <tr>
          <td class="tal-break-all" >品质认定情况</td>
          <td  class="tal-break-all" v-html="_.get(info, 'join_shou_ji_zhuan_ye.pin_zhi_ren_ding_qing_kuang', '')"></td>
          <td>研发费用总额</td>
          <td class="tal-break-all" >
            {{ _.get(info, 'join_shou_ji_zhuan_ye.yan_fa_fei_yong_zong_e') }}万元
          </td>
        </tr>
        <tr>
          <td class="tal-break-all">研发费用总额占<br/>营业收入总额比重</td>
          <td class="tal-break-all">
            {{ _.get(info, 'join_shou_ji_zhuan_ye.yan_fa_zhan_shou_ru_bi_zhong') }} %
          </td>
          <td class="tal-break-all">研发人员占<br/>全部职工比重</td>
          <td class="tal-break-all">
            {{ _.get(info, 'join_shou_ji_zhuan_ye.yan_fa_ren_yuan_zhan_bi') }} %
          </td>
        </tr>
        <tr>
          <td class="tal-break-all">
            拥有与主导产品有关的知识产权情况
          </td>
          <td class="tal-break-all" colspan="3">
            发明专利总数 {{ _.get(info, 'join_shou_ji_zhuan_ye.fa_ming_zhuan_li_zong_shu') }} 项<br/>
            实用新型专利总数 {{ _.get(info, 'join_shou_ji_zhuan_ye.shi_yong_xin_xing_zhuan_li_zong_shu') }} 项<br/>
            外观设计专利总数 {{ _.get(info, 'join_shou_ji_zhuan_ye.wai_guan_she_ji_zhuan_li_zong_shu') }} 项<br/>
            软件著作权总数 {{ _.get(info, 'join_shou_ji_zhuan_ye.ruan_jian_zhu_zuo_quan_zong_shu') }} 项<br/>
          </td>

        </tr>
        <tr>
          <td colspan="4" class="tal-break-all">
            <h5 class="tal-break-all">
              研发机构建设情况(企业自建或与高等院校、科研机构联合建立)
            </h5>
            {{ _.get(info, 'join_shou_ji_zhuan_ye.yan_fa_ji_gou_jian_she_qing_kuang') }}

          </td>
        </tr>
        <tr>
          <td class="tal-break-all" colspan="4">
            <h5 class="tal-break-all">近 3 年是否获得国家级科技奖励 </h5>
            {{ _.get(info, 'join_shou_ji_zhuan_ye.shi_fou_huo_de_guo_jia_ji_ke_ji_jiang_li') }}
          </td>
        </tr>

        <tr>
          <td class="tal-break-all">获得相关部门认定的称号</td>
          <td class="tal-break-all" colspan="3" v-html="_.get(info, 'join_shou_ji_bu_me.bu_men_ren_ding_de_cheng_hao', '')"></td>
        </tr>
        <tr>
          <td class="tal-break-all">是否有自主品牌</td>
          <td class="tal-break-all" colspan="3" v-html="_.get(info, 'join_shou_ji_bu_me.shi_fou_you_zi_zhu_pin_pai', '')"></td>
        </tr>
        <tr>
          <td class="tal-break-all">绿色制造体系认证情况</td>
          <td class="tal-break-all" colspan="3" v-html="_.get(info, 'join_shou_ji_bu_me.lv_se_zhi_zao_ti_xi_ren_zheng_qing_kuang', '')"></td>
        </tr>
        <tr>
          <td class="tal-break-all" colspan="4">
            <h5>企业诉求 </h5>
            {{ _.get(info, 'join_shou_ji_bu_me.qi_ye_su_qiu') }}
          </td>
        </tr>
        <tr>
          <td class="tal-break-all" colspan="4">
            <h5>对政策的疑问</h5>
            {{ _.get(info, 'join_shou_ji_bu_me.dui_zheng_ce_de_yi_wen') }}
          </td>
        </tr>
        <tr>
          <td  class="tal-break-all" colspan="4">
            <h5>当前市场行情描述， 对经济的信心、疫情影响，希望政府给到的政策和帮助、建议等</h5>
            {{ _.get(info, 'join_shou_ji_bu_me.dang_qian_shi_chang_hang_qing_miao_shu') }}
          </td>
        </tr>
        <tr>
          <td  class="tal-break-all" colspan="4">
            <h5>企业产品销售情况， 近几年销售（订单）、生产、营收情况 </h5>
            {{ _.get(info, 'join_shou_ji_bu_me.qi_ye_chan_pin_xiao_shou_qing_kuang') }}
          </td>
        </tr>
        <tr>
          <td  class="tal-break-all" colspan="4">
            <h5>所属行业前景描述， 行业发展、前景、转型情况等 </h5>
            {{ _.get(info, 'join_shou_ji_bu_me.suo_shu_hang_ye_qian_jing_miao_shu') }}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </van-action-sheet>
</template>

<script>
import {ref} from 'vue'
import _ from 'lodash'
import http from "@/api/http";
import to from 'await-to-js'
import XEUtils from "xe-utils";
import moment from "moment";
import {ids_zhuan_huan, zhuan_hang_ye, zhuan_shu_ju} from "@/views/ShouJiInfo/components/public";

export default {
  name: "SeeInfo",
  setup() {
    const status = ref(false)
    const info = ref({})

    async function show() {
      status.value = true
      XEUtils.clear(info.value, '')

      const [e, da] = await to(http.get("/gzhphp/shou_ji_info/getInfo"))
      console.log('da', e);
      console.log('da', da);
      if (_.get(da, 'code') !== 1) return


      let [e2, da2] = await to(http.get('/portal/index/get_suo_shu_hang_ye_he_xi_fen'))
      const [e4, jieDao] = await to(http.get('/portal/index/get_qi_ye_suo_shu_jie_dao'))
      const [e3, config] = await to(http.get('/portal/index/get_shou_ji_config'))

      info.value = _.get(da, 'data', {})
      info.value.shou_ji_time = moment(info.value.shou_ji_time).format('YYYY-MM-DD')
      const hang_ye = _.get(da2, 'data', [])
      info.value = zhuan_hang_ye(hang_ye, info.value)
      info.value = zhuan_shu_ju(_.get(config, 'data', []), info.value)

      info.value.qi_ye_suo_shu_jie_dao = ids_zhuan_huan(_.get(jieDao, 'data', []), info.value.qi_ye_suo_shu_jie_dao).join(' / ')
    }

    return {
      info,
      _,
      show,
      status
    }
  }
}
</script>

<style scoped>

</style>
