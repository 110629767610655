<template>
  <div class="tal-border-solid tal-border-gray-100 tal-border-0 tal-border-t-6">
    <van-field
        label-width="140"
        v-model="form.show_ji.yuan_gong_shu_liang"
        label="全职员工数量"
        placeholder="请输入"
        type="number"
        :error-message="_.get(getError,'show_ji.yuan_gong_shu_liang')"
    >
      <template #right-icon>人</template>
    </van-field>
  </div>
  <div class="tal-border-solid tal-border-gray-100 tal-border-0 tal-border-t-6">
    <van-field
        label-width="140"
        v-model="form.show_ji.yan_fa_ren_shu_liang"
        label="其中研发人员数量"
        placeholder="请输入"
        type="number"
        :error-message="_.get(getError,'show_ji.yan_fa_ren_shu_liang')"

    >
      <template #right-icon>人</template>
    </van-field>
  </div>
  <div class="tal-border-solid tal-border-gray-100 tal-border-0 tal-border-t-6">
    <van-field
        label-width="140"
        v-model="form.show_ji.ying_ye_shou_ru"
        label="营业收入"
        placeholder="请输入"
        type="number"
        :error-message="_.get(getError,'show_ji.ying_ye_shou_ru')"
    >
      <template #right-icon>万元</template>
    </van-field>
  </div>
  <div class="tal-border-solid tal-border-gray-100 tal-border-0 tal-border-t-6">
    <van-field
        label-width="140"
        v-model="form.show_ji.zhu_ying_ye_wu_shou_ru"
        label="主营业务收入"
        placeholder="请输入"
        type="number"
        :error-message="_.get(getError,'show_ji.zhu_ying_ye_wu_shou_ru')"
    >
      <template #right-icon>万元</template>
    </van-field>
  </div>
  <div class="tal-border-solid tal-border-gray-100 tal-border-0 tal-border-t-6">
    <van-field
        label-width="140"
        v-model="form.show_ji.zhu_ying_shou_ru_zeng_zhang_lv"
        label="主营业务收入增长率"
        placeholder="请输入"
        type="number"
        :error-message="_.get(getError,'show_ji.zhu_ying_shou_ru_zeng_zhang_lv')"
    >
      <template #right-icon>%</template>
    </van-field>
  </div>
  <div class="tal-border-solid tal-border-gray-100 tal-border-0 tal-border-t-6">
    <van-field
        label-width="140"
        v-model="form.show_ji.jing_li_run_zong_e"
        label="净利润总额"
        placeholder="请输入"
        type="number"
        :error-message="_.get(getError,'show_ji.jing_li_run_zong_e')"
    >
      <template #right-icon>万元</template>
    </van-field>
  </div>
  <div class="tal-border-solid tal-border-gray-100 tal-border-0 tal-border-t-6">
    <van-field
        label-width="140"
        v-model="form.show_ji.li_run_zeng_zhang_lv"
        label="净利润总额增长率"
        placeholder="请输入"
        type="number"
        :error-message="_.get(getError,'show_ji.li_run_zeng_zhang_lv')"
    >
      <template #right-icon>%</template>
    </van-field>
  </div>
  <div class="tal-border-solid tal-border-gray-100 tal-border-0 tal-border-t-6">
    <van-field
        label-width="140"
        v-model="form.show_ji.zi_chan_zong_e"
        label="资产总额"
        type="number"
        placeholder="请输入"
        :error-message="_.get(getError,'show_ji.zi_chan_zong_e')"
    >
      <template #right-icon>万元</template>
    </van-field>
  </div>
  <div class="tal-border-solid tal-border-gray-100 tal-border-0 tal-border-t-6">
    <van-field
        label-width="140"
        v-model="form.show_ji.zi_chan_fu_zhai_lv"
        label="资产负债率"
        type="number"
        placeholder="请输入"
        :error-message="_.get(getError,'show_ji.zi_chan_fu_zhai_lv')"
    >
      <template #right-icon>%</template>
    </van-field>
  </div>
  <div class="tal-border-solid tal-border-gray-100 tal-border-0 tal-border-t-6 tal-border-b-6">
    <select-xia-dan
        label-width="140"
        label="当前企业属于"
        :is-duo-ji="false"
        placeholder="请选择当前企业属于"
        v-model:id="form.show_ji.dang_qian_qi_ye_shu_yu"
        url="/portal/index/dang_qian_qi_ye_shu_yu"
        :error-message="_.get(getError,'show_ji.dang_qian_qi_ye_shu_yu')"
    />
  </div>
</template>

<script>
//经济效益和经营情况
import {inject,onMounted,reactive} from "vue";
import {get} from "@/api/http.js";
import XEUtils from "xe-utils";
import SelectXiaDan from "@/components/select/SelectXiaDan.vue";
import _ from 'lodash'
export default {
  name: "JingJiXiaoYiHeJingYingQingKuang",
  components: {SelectXiaDan},
  setup() {
    const getError = inject('get-error')
    const form = inject('lol-form')
    const selectList = reactive({
        '5':{name:'',children:[]}
    })
    onMounted(()=>{
      get('/portal/index/get_shou_ji_config').then(da => {
        selectList['5'] = da.data['5']
        selectList['5'].children = XEUtils.toArray(selectList['5'].children)
      })
    })

    return {
      _,
      getError,
      form,
      selectList
    }
  }
}
</script>

<style scoped>

</style>
