<template>
  <div class="">
    <div class="tal-border-solid tal-border-gray-100 tal-border-0 tal-border-t-6">
      <van-field label-width="100"
                 v-model="form.show_ji.qi_ye_ming_cheng"
                 label="企业名称"
                 :readonly="jinZhiXiuGai"
                 placeholder="请输入企业名称"
                 :error-message="_.get(getError,'show_ji.qi_ye_ming_cheng')"
      />
    </div>
    <div class="tal-border-solid tal-border-gray-100 tal-border-0 tal-border-t-6">
      <van-field
          label-width="100"
          v-model="form.show_ji.lian_xi_ren"
          label="联系人"
          placeholder="请输入联系人"
          :readonly="jinZhiXiuGai"
          :error-message="_.get(getError,'show_ji.lian_xi_ren')"
      />
    </div>
    <div class="tal-border-solid tal-border-gray-100 tal-border-0 tal-border-t-6">
      <van-field
          label-width="100"
          label="手机号"
          v-model="form.show_ji.mobile"
          placeholder="请输入手机号"
          :readonly="jinZhiXiuGai"
          :error-message="_.get(getError,'show_ji.mobile')"
      />
    </div>
    <div class="tal-border-solid tal-border-gray-100 tal-border-0 tal-border-t-6">
      <van-field label-width="85">
        <template #label>
          <div>
            统一社会<br/>
            信用代码
          </div>
        </template>
        <template #input>
          <van-field
              v-model="form.show_ji.xin_yong_dai_ma"
              placeholder="请输入统一社会信用代码"
              :readonly="jinZhiXiuGai"
              :error-message="_.get(getError,'show_ji.xin_yong_dai_ma')"
          />
        </template>
      </van-field>
    </div>
    <div class="tal-border-solid tal-border-gray-100 tal-border-0 tal-border-t-6">
      <van-field
          label-width="100"
          v-model="form.show_ji.wx_number"
          label="联系微信号"
          placeholder="请输入联系微信号"
          :error-message="_.get(getError,'show_ji.wx_number')"
      />
    </div>
    <lian-dong v-model:id1="form.show_ji.suo_shu_hang_ye" v-model:id2="form.show_ji.ju_ti_xi_fen_ling_yu"
               url="/portal/index/get_suo_shu_hang_ye_he_xi_fen">
      <template #input1="{getName}">
        <div class="tal-border-solid tal-border-gray-100 tal-border-0 tal-border-t-6">
          <van-field
              label-width="100"
              label="所属行业"
              placeholder="请选择所属行业"
              readonly
              is-link
              :error-message="_.get(getError,'show_ji.suo_shu_hang_ye')"
          >
            <template #input v-if="getName">{{ getName }}</template>
          </van-field>
        </div>
      </template>
      <template #input2="{getName}">
        <div class="tal-border-solid tal-border-gray-100 tal-border-0 tal-border-t-6">
          <van-field
              label-width="100"
              label="具体细分领域"
              placeholder="请选择具体细分领域"
              readonly
              is-link
              :error-message="_.get(getError,'show_ji.ju_ti_xi_fen_ling_yu')"
          >
            <template #input v-if="getName">{{ getName }}</template>
          </van-field>
        </div>
      </template>
    </lian-dong>
    <div class="tal-border-solid tal-border-gray-100 tal-border-0 tal-border-t-6">
      <select-xia-dan
          label-width="100"
          label="企业所属街道"
          placeholder="请选择企业所属街道"
          url="/portal/index/get_qi_ye_suo_shu_jie_dao"
          v-model:id="form.show_ji.qi_ye_suo_shu_jie_dao"
          :error-message="_.get(getError,'show_ji.qi_ye_suo_shu_jie_dao')"
      />
    </div>
    <div class="tal-border-solid tal-border-gray-100 tal-border-0 tal-border-t-6 ">
      <select-xia-dan
          url="/portal/index/get_qi_ye_lei_xing"
          label="企业类型"
          :is-duo-ji="false"
          placeholder="请选择企业类型"
          v-model:id="form.show_ji.qi_ye_lei_xing"
          label-width="100"
          :error-message="_.get(getError,'show_ji.qi_ye_lei_xing')"
      />
    </div>
    <div class="tal-border-solid tal-border-gray-100 tal-border-0 tal-border-t-6  tal-border-b-6">
      <div class="tal-pl-10 tal-pt-4 tal-text-van-label-color">
        公司简介（50~250字以内）
      </div>
      <div class="tal-pl-4">
        <van-field
            rows="2"
            v-model="form.show_ji.gong_si_jian_jie"
            autosize
            type="textarea"
            placeholder="请输入公司简介"
            maxlength="250"
            show-word-limit
            :error-message="_.get(getError,'show_ji.gong_si_jian_jie')"
        />
      </div>

    </div>

  </div>
</template>

<script>
import ShouJiDuoXuan from "@/views/ShouJiInfo/components/ShouJiDuoXuan";
import {onMounted, reactive, inject} from "vue";
import {get} from "@/api/http";
import XEUtils from "xe-utils";
import LianDong from "@/components/select/LianDong";
import SelectXiaDan from "@/components/select/SelectXiaDan";
import _ from 'lodash'
//企业基本信息
export default {
  name: 'QiYeJiBenXinXi',
  components: {SelectXiaDan, LianDong},
  setup() {
    const jinZhiXiuGai = inject('jin-zhi-xiu-gai')
    const form = inject('lol-form')
    const getError = inject('get-error')
    const selectList = reactive({
      '1': {name: '', children: []},
      '2': {name: '', children: []},
      '3': {name: '', children: []},
    })
    onMounted(() => {
      get('/portal/index/get_shou_ji_config').then(da => {
        selectList['1'] = XEUtils.toArray(da.data['1'])
        selectList['2'] = XEUtils.toArray(da.data['1'])
        selectList['3'] = XEUtils.toArray(da.data['1'])
      })
    })

    return {
      _,
      getError,
      form,
      jinZhiXiuGai,
      selectList
    }
  }
}
</script>


<style scoped>

</style>
