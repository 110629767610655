import XEUtils from "xe-utils";

//验证手机格式
export function validPhone(phone: string): boolean {
    if (
        !/^((13[0-9])|(14[5-9])|(15([0-3]|[5-9]))|(16[6-7])|(17[1-8])|(18[0-9])|(19[1|3])|(19[5|6])|(19[8|9]))\d{8}$/.test(
            XEUtils.toValueString(phone)
        )
    ) {
        return false
    } else {
        return true
    }
}


