<template>
  <div class="tal-border-solid tal-border-gray-100 tal-border-0 tal-border-t-6">
    <shou-ji-duo-xuan
        v-model:ids="form.shou_ji_zhuan_ye.ren_zheng_qing_kuang"
        v-model:shuo-ming="form.shou_ji_zhuan_ye.ren_zheng_qing_kuang_shuo_ming"
        :data-list="selectList['1'].children"
        :label="selectList['1'].name"
        :error-message="_.get(getError,'shou_ji_zhuan_ye.ren_zheng_qing_kuang',_.get(getError,'shou_ji_zhuan_ye.ren_zheng_qing_kuang_shuo_ming'))"
    />
  </div>
  <div class="tal-border-solid tal-border-gray-100 tal-border-0 tal-border-t-6">
    <shou-ji-duo-xuan
        v-model:ids="form.shou_ji_zhuan_ye.zhi_cheng_qing_kuang"
        v-model:shuo-ming="form.shou_ji_zhuan_ye.zhi_cheng_qing_kuang_shuo_ming"
        :data-list="selectList['2'].children"
        :label="selectList['2'].name"
        :error-message="_.get(getError,'shou_ji_zhuan_ye.zhi_cheng_qing_kuang',_.get(getError,'shou_ji_zhuan_ye.zhi_cheng_qing_kuang_shuo_ming'))"
    />
  </div>
  <div class="tal-border-solid tal-border-gray-100 tal-border-0 tal-border-t-6 tal-border-b-6">
    <shou-ji-duo-xuan
        v-model:ids="form.shou_ji_zhuan_ye.pin_zhi_ren_ding_qing_kuang"
        v-model:shuo-ming="form.shou_ji_zhuan_ye.pin_zhi_ren_ding_qing_kuang_shuo_ming"
        :data-list="selectList['3'].children"
        :label="selectList['3'].name"
        :error-message="_.get(getError,'shou_ji_zhuan_ye.pin_zhi_ren_ding_qing_kuang',_.get(getError,'shou_ji_zhuan_ye.pin_zhi_ren_ding_qing_kuang_shuo_ming'))"
    />
  </div>
</template>

<script>
import ShouJiDuoXuan from "@/views/ShouJiInfo/components/ShouJiDuoXuan";
import {onMounted, reactive, inject} from "vue";
import {get} from "@/api/http";
import XEUtils from "xe-utils";
import _ from 'lodash'
//精细化
export default {
  name: 'JingXiHua',
  components: {ShouJiDuoXuan},
  setup() {
    const getError = inject('get-error')
    const form = inject('lol-form')
    const selectList = reactive({
      '1': {name: '', children: []},
      '2': {name: '', children: []},
      '3': {name: '', children: []},
    })
    onMounted(() => {
      get('/portal/index/get_shou_ji_config').then(da => {
        selectList['1'] = da.data['1']
        selectList['1'].children = XEUtils.toArray(selectList['1'].children)
        selectList['2'] = da.data['2']
        selectList['2'].children = XEUtils.toArray(selectList['2'].children)
        selectList['3'] = da.data['3']
        selectList['3'].children = XEUtils.toArray(selectList['3'].children)
        console.log('selectList', selectList)
      })
    })

    return {
      form,
      getError,
      _,
      selectList
    }
  }
}
</script>


<style scoped>

</style>
