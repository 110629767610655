import XEUtils from "xe-utils";
import _ from "lodash";
import to from "await-to-js";
import http from "@/api/http";
import moment from "moment/moment";

//转收集信息
export function zhuan_shu_ju(config: any[], info, hang_hang_fu = '<br/>') {
    info = _.cloneDeep(info)

    info.shou_ji_time = moment(info.shou_ji_time).format('YYYY-MM-DD')

    //企业类型
    info.qi_ye_lei_xing = _.get(config, [4, 'children', info.qi_ye_lei_xing, 'name'], '--')
    info.dang_qian_qi_ye_shu_yu = _.get(config, [5, 'children', info.dang_qian_qi_ye_shu_yu, 'name'], '--')
    info.join_shou_ji_zhuan_ye.ren_zheng_qing_kuang = get_duo_xuan({
        ids: _.get(info, 'join_shou_ji_zhuan_ye.ren_zheng_qing_kuang', ''),
        pei_zhi: _.get(config, [1, 'children'], []),
        shuo_ming: _.get(info, 'join_shou_ji_zhuan_ye.ren_zheng_qing_kuang_shuo_ming', ''),
        hang_hang_bi: hang_hang_fu
    })

    info.join_shou_ji_zhuan_ye.zhi_cheng_qing_kuang = get_duo_xuan({
        hang_hang_bi: hang_hang_fu,
        ids: _.get(info, 'join_shou_ji_zhuan_ye.zhi_cheng_qing_kuang', ''),
        pei_zhi: _.get(config, [2, 'children'], []),
        shuo_ming: _.get(info, 'join_shou_ji_zhuan_ye.zhi_cheng_qing_kuang_shuo_ming', '')
    })

    info.join_shou_ji_zhuan_ye.pin_zhi_ren_ding_qing_kuang = get_duo_xuan({
        hang_hang_bi: hang_hang_fu,
        ids: _.get(info, 'join_shou_ji_zhuan_ye.pin_zhi_ren_ding_qing_kuang', ''),
        shuo_ming: _.get(info, 'join_shou_ji_zhuan_ye.pin_zhi_ren_ding_qing_kuang', ''),
        pei_zhi: _.get(config, [3, 'children'], [])
    })

    info.join_shou_ji_bu_me.bu_men_ren_ding_de_cheng_hao = get_duo_xuan({
        hang_hang_bi: hang_hang_fu,
        ids: _.get(info, 'join_shou_ji_bu_me.bu_men_ren_ding_de_cheng_hao', ''),
        shuo_ming: _.get(info, 'join_shou_ji_bu_me.bu_men_ren_ding_de_cheng_hao_shuo_ming', ''),
        pei_zhi: _.get(config, [6, 'children'], [])
    })

    info.join_shou_ji_bu_me.shi_fou_you_zi_zhu_pin_pai = get_duo_xuan({
        hang_hang_bi: hang_hang_fu,
        ids: _.get(info, 'join_shou_ji_bu_me.shi_fou_you_zi_zhu_pin_pai', ''),
        shuo_ming: _.get(info, 'join_shou_ji_bu_me.zi_you_pin_pai_name', ''),
        pei_zhi: _.get(config, [7, 'children'], [])
    })

    info.join_shou_ji_bu_me.lv_se_zhi_zao_ti_xi_ren_zheng_qing_kuang = get_duo_xuan({
        hang_hang_bi: hang_hang_fu,
        ids: _.get(info, 'join_shou_ji_bu_me.lv_se_zhi_zao_ti_xi_ren_zheng_qing_kuang', ''),
        shuo_ming: _.get(info, 'join_shou_ji_bu_me.lv_se_zhi_zao_ti_xi_ren_zheng_qing_kuang_shuo_ming', ''),
        pei_zhi: _.get(config, [8, 'children'], [])
    })

    return info;


}

//行业转换
export function zhuan_hang_ye(hang_ye, info) {
    //行业
    let hy = XEUtils.findTree(hang_ye, item => {
        return _.toString(_.get(item, 'id')) === _.toString(info.suo_shu_hang_ye)
    }, {children: 'children'})
    info.suo_shu_hang_ye = _.get(hy, 'item.name', '')

    //具体细分领域
    hy = XEUtils.findTree(hang_ye, item => {
        return _.toString(_.get(item, 'id')) === _.toString(info.ju_ti_xi_fen_ling_yu)
    }, {children: 'children'})
    info.ju_ti_xi_fen_ling_yu = _.get(hy, 'item.name', '')


    return info

}

interface duiXuanField {
    ids: string,//id的字段
    shuo_ming: string,//其他说明的字段
    pei_zhi: any[],//其他说明的字段
    hang_hang_bi: string,//换行符号
}

export function get_duo_xuan(con: duiXuanField) {
    const ids: string[] = _.toString(con.ids).split(',')
    const names: string[] = []
    _.each(ids, n => {
        const val = _.get(con.pei_zhi, [n,], {})
        if (_.isEmpty(val)) return
        if (_.get(val, 'input') === true) {
            names.push(`${val.name}：${con.shuo_ming}`)
        } else {
            names.push(val.name)
        }

    })

    return names.join(con.hang_hang_bi)
}

//多个id转换名称,id格式：1,2,3 或者：[1,2,3]
export function ids_zhuan_huan(configList, ids) {
    const name:string[] = new Array<string>();
    ids = _.split(ids, ',')
    _.each(ids, id => {
        XEUtils.eachTree(configList,n=>{
            if(_.toString(n.id) === _.toString(id)){
                name.push(_.get(n,'name',''))
            }
        },{children:'children'})
    })
    return name
}
