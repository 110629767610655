
import QiYeJiBenXinXi from './components/QiYeJiBenXinXi.vue'
import JingJiXiaoYiHeJingYingQingKuang from './components/JingJiXiaoYiHeJingYingQingKuang.vue'
import ZhuanYeHua from './components/ZhuanYeHua.vue'
import JingXiHua from './components/JingXiHua.vue'
import ChuangXinNengLi from './components/ChuangXinNengLi.vue'
import HuoDeXiangGuanBuMenRenDingDeChengHao from './components/HuoDeXiangGuanBuMenRenDingDeChengHao.vue'
import {computed, markRaw, onMounted, provide, ref, watch} from "vue";
import moment from "moment";
import {get, post} from "@/api/http.js";
import Schema, {Rules} from 'async-validator'
import XEUtils from "xe-utils";
import {validPhone} from "@/api/Validate";
import chaJian from "@/api/chaJian";
import WanBi from "@/views/ShouJiInfo/components/WanBi.vue";
import to from 'await-to-js'
import _ from 'lodash'
import {useRoute} from "vue-router";

export default {
  components: {WanBi},
  setup() {
    const getError = ref({});//错误信息
    provide('get-error', getError)

    const inputError = ref([]);//错误信息
    const route = useRoute();
    const rongQi = ref();//内容显示的div
    const loadOver = ref(false)
    const jin_zhi_xiu_gai = ref(false)//true = 禁止修改input的数据
    provide('jin-zhi-xiu-gai', jin_zhi_xiu_gai)


    const form = ref({
      show_ji: {
        qi_ye_ming_cheng: '',
        lian_xi_ren: '',
        mobile: '',
        xin_yong_dai_ma: '',
        tui_jian_ren: '' //推荐人账号
      },
      shou_ji_zhuan_ye: {},
      shou_ji_bu_me: {},
      pageIndex: 0,
    })


    //填写的信息
    provide('lol-form', form)
    const image = ref('')
    const jing_ji_name = `经济效益和经营情况  (${moment().subtract(1, 'year').format('YYYY')}年度)`

    const pageList = [
      {
        name: '企业基本信息',
        page: markRaw(QiYeJiBenXinXi),
        validate: {
          show_ji: {
            required: true,
            message: "请填写企业基本信息",
            type: 'object',
            fields: {
              qi_ye_ming_cheng: {
                type: 'string',
                required: true,
                message: '请输入'
              },
              lian_xi_ren: {
                type: 'string',
                required: true,
                message: '请输入'
              },
              mobile: [
                {
                  required: true,
                  type: 'string',
                  message: '请输入'
                },
                {
                  required: true,
                  asyncValidator(rule, value, callback) {
                    if (validPhone(value)) {
                      callback()
                    } else {
                      callback('手机格式错误')
                    }
                  }
                }
              ],
              xin_yong_dai_ma: {
                required: true,
                type: 'string',
                message: '请输入'
              },
              wx_number: {
                type: 'string',
                required: true,
                message: '请输入'
              },
              suo_shu_hang_ye: {
                type: 'string',
                required: true,
                message: '请选择'
              },
              ju_ti_xi_fen_ling_yu: {
                type: 'string',
                required: true,
                message: '请选择'
              },
              qi_ye_suo_shu_jie_dao: {
                type: 'string',
                required: true,
                message: '请选择'
              },
              qi_ye_lei_xing: {
                type: 'string',
                required: true,
                message: '请选择'
              },
              gong_si_jian_jie: [
                {
                  type: 'string',
                  required: true,
                  message: '请输入'
                },
                {
                  required: true,
                  asyncValidator(rule, value, callback) {
                    if (XEUtils.getSize(value) > 250) {
                      callback('不能大于250字')
                      return
                    }
                    if (XEUtils.getSize(value) < 50) {
                      callback('不能少于50字')
                      return
                    }
                    callback()
                  }
                }
              ]
            }
          },
        }
      },
      {
        name: jing_ji_name,
        page: markRaw(JingJiXiaoYiHeJingYingQingKuang),
        validate: {
          show_ji: {
            required: true,
            message: `请填写${jing_ji_name}`,
            type: 'object',
            fields: {
              yuan_gong_shu_liang: [
                {
                  required: true,
                  message: '请输入',
                },
                {
                  asyncValidator: validate_number
                }
              ],
              yan_fa_ren_shu_liang: [
                {
                  required: true,
                  message: '请输入',
                },
                {
                  asyncValidator: validate_number
                }
              ],
              ying_ye_shou_ru: [
                {
                  required: true,
                  message: '请输入',
                },
                {
                  asyncValidator: validate_number
                }
              ],
              zhu_ying_ye_wu_shou_ru: [
                {
                  required: true,
                  message: '请输入',
                },
                {
                  asyncValidator: validate_number
                }
              ],
              zhu_ying_shou_ru_zeng_zhang_lv: [
                {
                  required: true,
                  message: '请输入',
                },
                {
                  asyncValidator: validate_number
                }
              ],
              jing_li_run_zong_e: [
                {
                  required: true,
                  message: '请输入',
                },
                {
                  asyncValidator: validate_number
                }
              ],
              li_run_zeng_zhang_lv: [
                {
                  required: true,
                  message: '请输入',
                },
                {
                  asyncValidator: validate_number
                }
              ],
              zi_chan_zong_e: [
                {
                  required: true,
                  message: '请输入',
                },
                {
                  asyncValidator: validate_number
                }
              ],
              zi_chan_fu_zhai_lv: [
                {
                  required: true,
                  message: '请输入',
                },
                {
                  asyncValidator: validate_number
                }
              ],
              dang_qian_qi_ye_shu_yu: {
                required: true,
                message: `请选择`,
                type: 'string',
              },


            }
          }


        }
      },
      {
        name: '专业化',
        page: markRaw(ZhuanYeHua),
        validate: {
          shou_ji_zhuan_ye: {
            required: true,
            message: `请填写专业化`,
            type: 'object',
            fields: {
              zhu_dao_chan_pin_ming_cheng: {
                required: true,
                message: `请输入`,
                type: 'string',
              },
              zhu_dao_chan_pin_lei_bie: {
                required: true,
                message: `请输入`,
                type: 'string',
              },
              xi_fen_shi_chang_shi_jian: [
                {
                  required: true,
                  message: '请输入',
                },
                {
                  asyncValidator: validate_number
                }
              ],
              ying_ye_shou_ru_bi_zhong: [
                {
                  required: true,
                  message: '请输入',
                },
                {
                  asyncValidator: validate_number
                }
              ],
              shou_ru_ping_jun_zeng_zhang_lv: [
                {
                  required: true,
                  message: '请输入',
                },
                {
                  asyncValidator: validate_number
                }
              ],
              jin_qi_shi_fou_you_wu_gong: [
                {
                  required: true,
                  message: `请输入`,
                  type: 'string',
                },
                {
                  required: true,
                  asyncValidator(rule, value, callback) {
                    if (XEUtils.getSize(value) > 250) {
                      callback('不能大于250字')
                      return
                    }
                    callback()
                  }
                }
              ]


            }
          }
        }
      },
      {
        name: '精细化',
        page: markRaw(JingXiHua),
        validate: {
          shou_ji_zhuan_ye: {
            required: true,
            message: `请填写精细化`,
            type: 'object',
            fields: {
              ren_zheng_qing_kuang: {
                required: true,
                message: `请选择`,
                type: 'string',
              },
              ren_zheng_qing_kuang_shuo_ming: {

                asyncValidator(rule, value, callback) {
                  let ids = XEUtils.get(form.value, 'shou_ji_zhuan_ye.ren_zheng_qing_kuang')
                  ids = XEUtils.toValueString(ids).split(',')

                  if (!XEUtils.includes(ids, '5')) {
                    callback()
                    return
                  }
                  if (XEUtils.getSize(XEUtils.toValueString(value)) === 0) {
                    callback('请输入说明')
                    return;
                  }
                  callback()

                }
              },
              zhi_cheng_qing_kuang: {
                required: true,
                message: `请选择`,
                type: 'string',
              },
              zhi_cheng_qing_kuang_shuo_ming: {
                asyncValidator(rule, value, callback) {

                  let ids = XEUtils.get(form.value, 'shou_ji_zhuan_ye.zhi_cheng_qing_kuang')
                  ids = XEUtils.toValueString(ids).split(',')

                  if (!XEUtils.includes(ids, '6')) {
                    callback()
                    return
                  }


                  if (XEUtils.getSize(XEUtils.toValueString(value)) === 0) {
                    callback('请输入说明')
                    return;
                  }
                  callback()

                }
              },
              pin_zhi_ren_ding_qing_kuang: {
                required: true,
                message: `请选择`,
                type: 'string',
              },
              pin_zhi_ren_ding_qing_kuang_shuo_ming: {
                asyncValidator(rule, value, callback) {
                  let ids = XEUtils.get(form.value, 'shou_ji_zhuan_ye.pin_zhi_ren_ding_qing_kuang')
                  ids = XEUtils.toValueString(ids).split(',')

                  if (!XEUtils.includes(ids, '3')) {
                    callback()
                    return
                  }
                  if (XEUtils.getSize(XEUtils.toValueString(value)) === 0) {
                    callback('请输入说明')
                    return;
                  }
                  callback()

                }
              }
            }
          }
        }
      },
      {
        name: '创新能力',
        page: markRaw(ChuangXinNengLi),
        validate: {
          shou_ji_zhuan_ye: {
            required: true,
            message: `请填写专业化`,
            type: 'object',
            fields: {
              yan_fa_fei_yong_zong_e: [
                {
                  required: true,
                  message: '请输入',
                },
                {
                  asyncValidator: validate_number
                }
              ],
              yan_fa_zhan_shou_ru_bi_zhong: [
                {
                  required: true,
                  message: '请输入',
                },
                {
                  asyncValidator: validate_number
                }
              ],
              yan_fa_ren_yuan_zhan_bi: [
                {
                  required: true,
                  message: '请输入',
                },
                {
                  asyncValidator: validate_number
                }
              ],
              fa_ming_zhuan_li_zong_shu: [
                {
                  required: true,
                  message: '请输入',
                },
                {
                  asyncValidator: validate_number
                }
              ],
              shi_yong_xin_xing_zhuan_li_zong_shu: [
                {
                  required: true,
                  message: '请输入',
                },
                {
                  asyncValidator: validate_number
                }
              ],
              wai_guan_she_ji_zhuan_li_zong_shu: [
                {
                  required: true,
                  message: '请输入',
                },
                {
                  asyncValidator: validate_number
                }
              ],
              ruan_jian_zhu_zuo_quan_zong_shu: [
                {
                  required: true,
                  message: '请输入',
                },
                {
                  asyncValidator: validate_number
                }
              ],
              yan_fa_ji_gou_jian_she_qing_kuang: {
                message: "请输入",
                type: 'string',
              },
              shi_fou_huo_de_guo_jia_ji_ke_ji_jiang_li: {
                message: "请输入",
                type: 'string',
              },

            }
          }
        }
      },
      {
        name: '获得相关部门认定的称号（多选）',
        page: markRaw(HuoDeXiangGuanBuMenRenDingDeChengHao),
        validate: {
          shou_ji_bu_me: {
            required: true,
            message: `请选择`,
            type: 'object',
            fields: {
              bu_men_ren_ding_de_cheng_hao: {
                required: true,
                message: '请选择',
                type: 'string'
              },
              bu_men_ren_ding_de_cheng_hao_shuo_ming: {
                asyncValidator(rule, value, callback) {
                  let ids = XEUtils.get(form.value, 'shou_ji_bu_me.bu_men_ren_ding_de_cheng_hao')
                  ids = XEUtils.toValueString(ids).split(',')
                  if (!XEUtils.includes(ids, '6')) {
                    callback()
                    return;
                  }
                  if (XEUtils.getSize(XEUtils.toValueString(value)) === 0) {
                    callback('请输入说明')
                    return;
                  }
                  callback()
                }
              },
              shi_fou_you_zi_zhu_pin_pai: {
                required: true,
                message: '请选择',
                type: 'string'
              },
              zi_you_pin_pai_name: {
                asyncValidator(rule, value, callback) {
                  let ids = XEUtils.get(form.value, 'shou_ji_bu_me.shi_fou_you_zi_zhu_pin_pai')
                  ids = XEUtils.toValueString(ids).split(',')
                  if (!XEUtils.includes(ids, '2')) {
                    callback()
                    return;
                  }
                  if (XEUtils.getSize(XEUtils.toValueString(value)) === 0) {
                    callback('请输入品牌名称')
                    return;
                  }
                  callback()

                }
              },
              lv_se_zhi_zao_ti_xi_ren_zheng_qing_kuang: {
                required: true,
                message: '请选择',
                type: 'string'
              },
              lv_se_zhi_zao_ti_xi_ren_zheng_qing_kuang_shuo_ming: {
                asyncValidator(rule, value, callback) {
                  let ids = XEUtils.get(form.value, 'shou_ji_bu_me.lv_se_zhi_zao_ti_xi_ren_zheng_qing_kuang')
                  ids = XEUtils.toValueString(ids).split(',')
                  if (!XEUtils.includes(ids, '6')) {
                    callback()
                    return;
                  }
                  if (XEUtils.getSize(XEUtils.toValueString(value)) === 0) {
                    callback('请输入说明')
                    return;
                  }
                  callback()
                }
              },
              dang_qian_shi_chang_hang_qing_miao_shu: [
                {
                  required: true,
                  type: 'string',
                  message: '请输入'
                },
                {
                  required: true,
                  type: 'string',
                  max: 250,
                  message: '不能超过250字'
                },
              ],
              qi_ye_chan_pin_xiao_shou_qing_kuang: [
                {
                  required: true,
                  type: 'string',
                  message: '请输入'
                },
                {
                  required: true,
                  type: 'string',
                  max: 250,
                  message: '不能超过250字'
                },
              ],
              suo_shu_hang_ye_qian_jing_miao_shu: [
                {
                  required: true,
                  type: 'string',
                  message: '请输入'
                },
                {
                  required: true,
                  type: 'string',
                  max: 250,
                  message: '不能超过250字'
                },
              ]
            }
          }
        }
      }
    ];
    const tianXieOver = ref(false) //true = 填写完毕

    //每次修改都保存到缓存
    const tong_bu_dao_huan_cun = XEUtils.debounce(() => {
      post('/gzhphp/shou_ji_info/edit_tian_xie_info', form.value, {load: false})
    }, 1000)


    onMounted(async () => {
      const [e, da] = await to(get('/gzhphp/shou_ji_info/get_image'))
      image.value = XEUtils.get(da, 'data.url')

      //获取填写过的数据
      const [e2, da2] = await to(get('/gzhphp/shou_ji_info/edit_tian_xie_info'))
      if (!_.isEmpty(_.get(da2, 'data'))) {
        form.value = XEUtils.merge(form.value, XEUtils.get(da2, 'data'))
        form.value.pageIndex = XEUtils.toInteger(form.value.pageIndex)
        if (form.value.pageIndex > pageList.length - 1) {
          form.value.pageIndex = pageList.length - 1
        }
        tianXieOver.value = _.get(form.value, 'isOver') === '填写完毕'
      } else {
        const [e3, da3] = await to(get("/gzhphp/shou_ji_info/get_basis_info"))
        form.value.show_ji.qi_ye_ming_cheng = _.get(da3, 'data.qi_ye_ming_cheng', '')
        form.value.show_ji.lian_xi_ren = _.get(da3, 'data.lian_xi_ren', '')
        form.value.show_ji.mobile = _.get(da3, 'data.mobile', '')
        form.value.show_ji.xin_yong_dai_ma = _.get(da3, 'data.xin_yong_dai_ma', '')
      }


      loadOver.value = true

      //监听相间产变化
      watch(() => form, () => {
        validate()
        tong_bu_dao_huan_cun()
      }, {deep: true, immediate: true})

    })

    //点击上一页
    function clickShangYiYe() {
      form.value.pageIndex--
      rongQi.value.scrollTop = 0
    }


    //点击下页
    function clickXiaYiYe() {
      if (_.size(inputError.value)) {
        return
      }
      rongQi.value.scrollTop = 0
      form.value.pageIndex++

    }

    const validate = _.debounce(async () => {
      const schema = new Schema(XEUtils.get(pageList, [XEUtils.toValueString(form.value.pageIndex), 'validate'], {}) as Rules)
      const [e] = await to(schema.validate(form.value, {first: true}))
      inputError.value = _.get(e, 'errors', [])

      //全局错误信息
      getError.value = {}
      if (!_.isEmpty(inputError.value)) {
        _.set(
            getError.value,
            _.get(inputError.value, '0.field'),
            _.get(inputError.value, '0.message')
        )
      }

    }, 100)

    function validate_number(rule, value, callback) {
      value = _.toNumber(value)
      if (_.isNaN(value)) callback('必须是数字')
      callback()
    }

    //字符专数字
    function zhuan_shu_zi(val) {
      val = _.toNumber(val)
      console.log('is number', val, _.isNumber(val))
      if (_.isNaN(val)) return ''
      return val
    }

    function clickSubmit() {
      if (_.size(inputError.value)) {
        return
      }

      XEUtils.set(form.value, 'show_ji.tui_jian_ren', XEUtils.get(route, 'query.tui_jian_ren', ''))
      post("/gzhphp/shou_ji_info/edit_shou_ji_info", form.value).then(da => {
        tianXieOver.value = true
      })
    }

//显示那个按钮
    const showBtn = computed(() => {
      let btn = {
        shang_yi_ye: true,//上一页
        xia_yi_ye: true,//下一页
        que_ding: true//确定
      }
      if (form.value.pageIndex === 0) {
        btn.shang_yi_ye = false
      }
      if (tianXieOver.value) {
        btn.shang_yi_ye = false
        btn.xia_yi_ye = false
        btn.que_ding = false
      }
      if (pageList.length - 1 !== form.value.pageIndex) {
        btn.que_ding = false

      }
      if (btn.que_ding) {
        btn.xia_yi_ye = false
      }

      return btn

    })

    function guanBi() {
      chaJian.closeWeb()
    }

    //重新填写、删除缓存
    function del_cache() {
      get('/gzhphp/shou_ji_info/del_cache').then(da => {
        location.reload()
      })
    }

    return {
      jin_zhi_xiu_gai,
      del_cache,
      guanBi,
      form,
      loadOver,
      image,
      pageList,
      clickShangYiYe,
      clickXiaYiYe,
      clickSubmit,
      showBtn,
      tianXieOver,
      rongQi,
      inputError
    }
  }
}
