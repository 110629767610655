
//下啦单选
/**
 *  ext.emit('update:id', id)
 *  ext.emit('update:name', name)
 */
import {get} from "@/api/http.js";
import {ref, onMounted, watch, nextTick} from "vue";
import XEUtils from "xe-utils";

export default {
  name: "SelectXiaDan",
  props: {
    errorMessage: String,
    label: String,
    placeholder: String,
    inputShow: {
      type: Boolean,
      default() {
        return true
      }
    },
    labelWidth: [String, Number],
    url: {
      request: true,
      type: String
    },
    //默认多级   false = 一级选择  一级选择必须配置
    isDuoJi: {
      type: Boolean,
      default() {
        return true;
      }
    },
    id: [String, null],
  },
  setup(props, ext) {
    const status = ref<boolean>(false)
    const list = ref([]) //可以选择哪些数据
    let dataList = [];//获取选择的数据
    let selectName = ref('')//选中的名称
    const columnsFieldNames = {
      text: 'name', values: 'id', children: 'children'
    }
    onMounted(() => {
      get(props.url).then(da => {
        dataList = da.data
        if (props.isDuoJi) {
          list.value = dataList
        } else {
          XEUtils.eachTree(dataList, n => {
            list.value.push(XEUtils.get(n, 'name', ''))
          }, {children: 'children'})
        }
        nextTick(() => {
          setDefault()
        })

      })
    })

    function setDefault() {
      watch(() => props.id, () => {
        let item = []
        let ids = XEUtils.toValueString(props.id).split(',')
        XEUtils.eachTree(dataList, n => {
          if (XEUtils.includes(ids, XEUtils.toValueString(XEUtils.get(n, 'id')))) {
            item.push(n)
          }
        }, {children: 'children'})
        yes(item)

      }, {immediate: true})
    }

    function show() {
      status.value = true
    }

    function close() {
      status.value = false
    }

    function yes(item) {

      let id = ''
      let name = ''

      if (XEUtils.isArray(item)) {
        //传来数组
        id = XEUtils.map(item, n => n.id).join(',')
        name = XEUtils.map(item, n => n.name).join(',')
      } else {

        //传来名称，自动获取id
        name = XEUtils.toValueString(item)
        XEUtils.eachTree(dataList, n => {
          if (XEUtils.get(n, 'name') === item) {
            id = XEUtils.get(n, 'id')
          }
        }, {children: 'children'})

      }


      selectName.value = XEUtils.toValueString(name).split(',').join(' / ')

      ext.emit('update:id', XEUtils.toValueString(id))
      ext.emit('update:name', name)
      status.value = false
    }

    return {
      selectName,
      yes,
      close,
      show,
      status,
      list,
      columnsFieldNames
    }
  }
}
