
// 创新能力
import {inject} from "vue";
import _ from 'lodash'
export default {
  name: "ChuangXinNengLi",
  setup() {
    const getError = inject('get-error')
    const form = inject('lol-form')
    return {
      _,
      form,getError
    }
  }
}
