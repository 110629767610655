
import {computed, reactive, watch} from "vue";
import XEUtils from "xe-utils";

/**
 *  emit('update:ids', XEUtils.toValueString(form.ids))
 *  emit('update:shuoMing', form.shuoMing)
 */
export default {
  name: "shouJiDuoXuan",
  props: {
    errorMessage: String,
    //默认多选 单选=radio、多选=checkbox
    inputType: {
      type: String,
      default() {
        return 'checkbox'
      }
    },
    dataList: Array,
    label: String,
    labelWidth: [Number, String],
    ids: [String, Number],
    shuoMing: String //没有显示input,会返回undefined
  },
  setup(props, {emit}) {
    interface formObj {
      ids: string[] | number[] | string,
      shuoMing: string
    }

    const form = reactive<formObj>({
      ids: props.inputType === 'checkbox' ? [] : '',
      shuoMing: '',
    })

    //是否显示输入
    function isShowInput(item) {
      if (!item.input) return false
      return XEUtils.includes(form.ids, item.id)
    }

    //配置label的宽度
    const style = computed(() => {
      if (XEUtils.isUndefined(props.labelWidth)) {
        return {}
      }
      return {
        width: `${props.labelWidth}px`
      }
    })

    //监听修改
    const ids_fang_dou = XEUtils.debounce(() => {
      emit('update:ids', XEUtils.toValueString(form.ids))
      emit('update:shuoMing', form.shuoMing)
    }, 100)


    function data_update_event() {
      ids_fang_dou()
    }

    watch(() => form.shuoMing, () => {
      ids_fang_dou()
    })


    //监听上级数据同步本页面

    function shang_ji_ids_fang_dou() {
      let ids = XEUtils.toValueString(props.ids).split(',')

      ids = XEUtils.filter(ids, n => !XEUtils.isEmpty(n))
      if (props.inputType === 'checkbox') {
        form.ids = ids
      } else {
        form.ids = ids.join(',')
      }
      form.shuoMing = props.shuoMing
    }

    watch(() => [props.ids, props.shuoMing], () => {
      shang_ji_ids_fang_dou()
    }, {
      immediate: true
    })


    //选中其他，true = 选中
    function isXuanZongInput(): boolean {
      let ids: string[] = XEUtils.toValueString(XEUtils.get(form, 'ids')).split(',')
      let val = XEUtils.find(props.dataList, n => {
        return XEUtils.includes(ids, XEUtils.toValueString(n.id)) && n.input === true;
      })
      return !XEUtils.isUndefined(val)
    }

    const getDataList = computed(() => {
      interface n_type {
        id: number | string
      }

      return XEUtils.map(props.dataList, (n: n_type) => {
        n.id = XEUtils.toValueString(n.id)
        return n
      })
    })

    //控制显示单选、多选
    const getType = computed(() => {
      if (props.inputType === 'radio') {
        return {
          group: 'van-radio-group',
          input: 'van-radio',
          inputConfig: {}
        }
      }
      return {
        group: 'van-checkbox-group',
        input: 'van-checkbox',
        inputConfig: {
          shape: "square"
        }
      }
    })

    return {
      style,
      getType,
      data_update_event,
      getDataList,
      isShowInput,
      form
    }
  }
}
