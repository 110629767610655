<template>
  <div class="tal-border-solid tal-border-gray-100 tal-border-0 tal-border-t-6">
    <van-field
        label-width="160"
        v-model="form.shou_ji_zhuan_ye.zhu_dao_chan_pin_ming_cheng"
        label="主导产品名称（中文）"
        placeholder="请输入"
        :error-message="_.get(getError,'shou_ji_zhuan_ye.zhu_dao_chan_pin_ming_cheng')"
    />
  </div>
  <div class="tal-border-solid tal-border-gray-100 tal-border-0 tal-border-t-6">
    <van-field
        label-width="160"
        v-model="form.shou_ji_zhuan_ye.zhu_dao_chan_pin_lei_bie"
        label="主导产品类别"
        placeholder="请输入"
        :error-message="_.get(getError,'shou_ji_zhuan_ye.zhu_dao_chan_pin_lei_bie')"
    />
  </div>
  <div class="tal-border-solid tal-border-gray-100 tal-border-0 tal-border-t-6">
    <van-field label-width="145">
      <template #label>
        <div>
          企业从事特定<br/>
          细分市场时间
        </div>
      </template>
      <template #input>
        <van-field
            v-model="form.shou_ji_zhuan_ye.xi_fen_shi_chang_shi_jian"
            type="number"
            placeholder="请输入"
            :error-message="_.get(getError,'shou_ji_zhuan_ye.xi_fen_shi_chang_shi_jian')"
        />
      </template>
      <template #right-icon>年</template>
    </van-field>
  </div>
  <div class="tal-border-solid tal-border-gray-100 tal-border-0 tal-border-t-6">
    <van-field label-width="145">
      <template #label>
        <div>
          主营业务收入占<br/>
          营业收入比重
        </div>
      </template>
      <template #input>
        <van-field
            type="number"
            v-model="form.shou_ji_zhuan_ye.ying_ye_shou_ru_bi_zhong"
            placeholder="请输入"
            :error-message="_.get(getError,'shou_ji_zhuan_ye.ying_ye_shou_ru_bi_zhong')"
        />
      </template>
      <template #right-icon>%</template>
    </van-field>
  </div>
  <div class="tal-border-solid tal-border-gray-100 tal-border-0 tal-border-t-6">
    <van-field label-width="145">
      <template #label>
        <div>
          近 2 年主营业务<br/>
          收入平均增长率
        </div>
      </template>
      <template #input>
        <van-field
            v-model="form.shou_ji_zhuan_ye.shou_ru_ping_jun_zeng_zhang_lv"
            placeholder="请输入"
            type="number"
            :error-message="_.get(getError,'shou_ji_zhuan_ye.shou_ru_ping_jun_zeng_zhang_lv')"
        />
      </template>
      <template #right-icon>%</template>
    </van-field>
  </div>
  <div class="tal-border-solid tal-border-gray-100 tal-border-0 tal-border-t-6 tal-border-b-6">
    <div class="tal-pl-10 tal-pr-10 tal-pt-4  tal-text-van-label-color">
      近期是否有无工厂（生产线）项目新建、扩建、技改等计划（250字以内）
    </div>
    <div class="tal-pl-4">
      <van-field
          rows="2"
          v-model="form.shou_ji_zhuan_ye.jin_qi_shi_fou_you_wu_gong"
          autosize
          type="textarea"
          placeholder="请输入"
          maxlength="250"
          show-word-limit
          :error-message="_.get(getError,'shou_ji_zhuan_ye.jin_qi_shi_fou_you_wu_gong')"
      />
    </div>
  </div>

</template>

<script>
//专业化
import {inject} from "vue";
import _ from 'lodash'
export default {
  name: "ZhuanYeHua",
  setup(){
    const getError = inject('get-error')
    const form = inject('lol-form')
    return {
      _,
      getError,
      form
    }
  }
}
</script>

<style scoped>

</style>
